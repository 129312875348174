import {Injectable} from '@angular/core';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {User} from '../models/user.model';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {JwtService} from './jwt.service';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable();

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private router: Router,
  ) {
  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  populate() {
    // If JWT detected, attempt to get & store user's info
    const userId = JwtService.getUser();
    if (JwtService.getToken()) {
      this.apiService.get(`${environment.api_base}` + 'user/' + userId + '/', null, true)
        .subscribe(
          data => {
            const userModel = new User();
            userModel.userId = parseInt(userId, 10);
            userModel.username = data.username;
            userModel.email = data.email;
            userModel.token = JwtService.getToken();
            this.setAuth(userModel);
          },
          err => this.purgeAuth()
        );
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  setAuth(user: User) {
    // Save JWT sent from server in localstorage
    JwtService.saveUser(user);
    JwtService.saveToken(user.token);
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    JwtService.destroyToken();
    JwtService.destroyUser();
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }

  login(credentials) {
    return this.http.post<any>(`${environment.api_login}`, credentials)
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          const userModel = new User();
          userModel.userId = user.user_id;
          userModel.username = user.username;
          userModel.email = user.email;
          userModel.token = user.token;
          this.setAuth(userModel);
          this.router.navigateByUrl('/');
        }
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    this.purgeAuth();
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  // update(user): Observable<User> {
  //   return this.apiService
  //     .put('/user', { user })
  //     .pipe(map(data => {
  //       // Update the currentUser observable
  //       this.currentUserSubject.next(data.user);
  //       return data.user;
  //     }));
  // }
}
