import {Component, OnInit} from '@angular/core';
import {TaskService} from '../core/services/task.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.sass']
})
export class TasksComponent implements OnInit {

  workers;

  constructor(
    private taskService: TaskService
  ) {
  }

  ngOnInit() {
    this.loadActiveTasks();
  }

  loadActiveTasks(event = null) {
    let tmp = null;
    this.workers = null;
    if (event) {
      tmp = event.target.innerHTML;
      event.target.disabled = true;
      event.target.innerHTML = 'Loading ...';
    }
    this.taskService.getActiveTasks().subscribe(data => {
      this.workers = data;
      if (event) {
        event.target.disabled = false;
        event.target.innerHTML = tmp;
      }
    });
  }
}
