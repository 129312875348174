import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from './core/services/user.service';
import {User} from './core/models/user.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  title = 'InstaFrontend2';
  currentUser: User;


  constructor(
    private router: Router,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    this.userService.populate();

    this.userService.currentUser.subscribe(
      (userData) => {
        this.currentUser = userData;
      }
    );

  }
}
