import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {AuthGuardService} from './core/services/auth-guard.service';

const routes: Routes = [
  {
    path: 'accounts',
    loadChildren: './insta-accounts/insta-accounts.module#InstaAccountsModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'tasks',
    loadChildren: './tasks/tasks.module#TasksModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'watcher',
    loadChildren: './watcher/watcher.module#WatcherModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'user',
    loadChildren: './profile/profile.module#ProfileModule'
  },
  {
    path: 'stats',
    loadChildren: './stats/stats.module#StatsModule'
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false, preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
