import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TopAccountsComponent} from './insta-accounts/top-accounts/top-accounts.component';
import {TopPostsComponent} from './insta-post/top-posts/top-posts.component';

const routes: Routes = [
  {
    path: '',
    component: TopAccountsComponent
  },
  {
    path: 'top-posts',
    component: TopPostsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StatsRoutingModule {
}
