import {Component, OnInit} from '@angular/core';
import {StatsService} from '../../../core/services/stats.service';

@Component({
  selector: 'app-top-accounts',
  templateUrl: './top-accounts.component.html',
  styleUrls: ['./top-accounts.component.sass']
})
export class TopAccountsComponent implements OnInit {
  topAccounts = [];

  constructor(
    private statsService: StatsService
  ) {
  }

  ngOnInit() {
    this.statsService.getTopAccounts().subscribe(data => {
      this.topAccounts = data;
    });
  }


}
