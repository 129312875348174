import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApiService} from './services/api.service';
import {JwtService} from './services/jwt.service';
import {InstaAccountsService} from './services/insta-accounts.service';
import {UserService} from './services/user.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpTokenInterceptor} from './interceptors/http.token.interceptor';
import {HumanizenumberPipe} from './pipes/humanizenumber.pipe';

@NgModule({
  declarations: [HumanizenumberPipe],
  imports: [
    CommonModule,
  ],
  exports: [
    HumanizenumberPipe
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true},
    ApiService,
    JwtService,
    InstaAccountsService,
    UserService,
  ]
})
export class CoreModule {
}
