import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  constructor(
    private apiService: ApiService
  ) {
  }

  getTopAccounts() {
    return this.apiService.get('stats/account-top-follower/');
  }

  getDatabaseStats(): Observable<any> {
    return this.apiService.get(`stats/`);
  }

  getDatabaseStatsMediaTypes(): Observable<any> {
    return this.apiService.get(`stats/posttypes/`);
  }

  getTopPostsByViews() {
    return this.apiService.get('stats/post-top-views/');
  }

  getTopPostsByComments() {
    return this.apiService.get('stats/post-top-comments/');
  }

  getTopPostsByLikes() {
    return this.apiService.get('stats/post-top-likes/');
  }

  getAccountFollowerStatsPerDay(username) {
    return this.apiService.get(`stats/${username}/follower`);
  }

  getAccountMediaStatsPerDay(username, limit = 100) {
    return this.apiService.get(`stats/${username}/media?limit=${limit}`);
  }

  getAccountMediaLikeStatsPerDay(username, limit = 100) {
    return this.apiService.get(`stats/${username}/media/likes?limit=${limit}`);
  }

  getAccountER(username, limit = 100) {
    return this.apiService.get(`stats/${username}/er?limit=${limit}`);

  }

  getAccountMediaTypes(username) {
    return this.apiService.get(`stats/${username}/media/types`);
  }

  getAccountsGroupedByFollower() {
    return this.apiService.get('stats/grouped/follower');
  }
}
