import {Component, OnInit} from '@angular/core';
import {InstaAccountsService} from '../core/services/insta-accounts.service';
import * as Chart from 'chart.js';
import {StatsService} from '../core/services/stats.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
  dbStats;
  loading = false;
  chart: any;
  barChartMediaTypes: any;

  constructor(
    private accountService: InstaAccountsService,
    private statsService: StatsService) {
  }

  ngOnInit() {
    if (!this.dbStats) {
      this.loading = true;
    }
    this.statsService.getDatabaseStats().subscribe(data => {
      this.dbStats = data;

      const percentPrivate = data.accounts_is_private / data.accounts * 100;
      const percentVerified = data.accounts_is_verified / data.accounts * 100;
      const percentBusiness = data.accounts_is_business / data.accounts * 100;
      const percentCategory = data.accounts_has_category / data.accounts * 100;

      this.getAccountDBChart('canvasPrivate', Math.round(percentPrivate * 100) / 100, 'Private', '#63c484');
      this.getAccountDBChart('canvasBusiness', Math.round(percentBusiness * 100) / 100, 'Business', '#c46239');
      this.getAccountDBChart('canvasVerified', Math.round(percentVerified * 100) / 100, 'Verified', '#c4638f');
      this.getAccountDBChart('canvasCategory', Math.round(percentCategory * 100) / 100, 'Has Category', '#5aaac4');
    }).add(() => {
      this.loading = false;
    });

    this.statsService.getDatabaseStatsMediaTypes().subscribe(data => {
      this.getMediaTypes(data);
    });
  }

  getAccountDBChart(id, percent, name, color) {
    const data = {
      datasets: [

        {
          data: [percent, Math.round((100 - percent) * 100) / 100],
          backgroundColor: [color],
        },
      ],

      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: [
        name,
        'Rest',
      ],
    };

    const options = [];

    this.chart = new Chart(id, {
      type: 'pie',
      data,
    });
  }

  getMediaTypes(apiData) {
    const data = {
      datasets: [
        {
          data: [],
          backgroundColor: ['#c46239', '#63c484', '#5aaac4'],
        },
      ],

      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: [],
    };

    let sum = 0;
    for (const obj of apiData) {
      sum = sum + obj.count;
    }

    for (const obj of apiData) {
      const percent = Math.round(obj.count / sum * 100 * 100) / 100;
      data.datasets[0].data.push(percent);
      let label;
      switch (obj.media_type) {
        case 1:
          label = 'Image';
          break;
        case 2:
          label = 'Video';
          break;
        case 8:
          label = 'Carousel';

      }
      data.labels.push(label);
    }


    this.barChartMediaTypes = new Chart('canvasMediaTypes', {
      type: 'pie',
      data,
    });
  }
}
