import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {StatsRoutingModule} from './stats-routing.module';
import {TopAccountsComponent} from './insta-accounts/top-accounts/top-accounts.component';
import {CoreModule} from '../core/core.module';
import {PostStatsComponent} from './insta-post/post-stats/post-stats.component';
import {TopPostsComponent} from './insta-post/top-posts/top-posts.component';
import {PostCardComponent} from '../post/post-card/post-card.component';
import {GroupedComponent} from './insta-accounts/grouped/grouped.component';

@NgModule({
  declarations: [
    TopAccountsComponent,
    PostStatsComponent,
    TopPostsComponent,
    PostCardComponent,
    GroupedComponent
  ],
  exports: [
    GroupedComponent
  ],
  imports: [
    CommonModule,
    StatsRoutingModule,
    CoreModule,
  ]
})
export class StatsModule {
}
