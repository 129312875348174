import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {InstaAccountsService} from '../../../core/services/insta-accounts.service';

@Component({
  selector: 'app-my-line-chart',
  templateUrl: './my-line-chart.component.html',
  styleUrls: ['./my-line-chart.component.sass']
})
export class MyLineChartComponent implements OnInit {

  @Input()
  username: string;
  public lineChartDataFollower: ChartDataSets[] = [{}];
  public lineChartDataFollowing: ChartDataSets[] = [{}];
  public lineChartDataMedia: ChartDataSets[] = [{}];
  public lineChartDataUsertags: ChartDataSets[] = [{}];
  public lineChartDataGeoMedia: ChartDataSets[] = [{}];
  public lineChartLabelsFollower: Label[] = [];
  public lineChartLabelsFollowing: Label[] = [];
  public lineChartLabelsMedia: Label[] = [];
  public lineChartLabelsUsertags: Label[] = [];
  public lineChartLabelsGeoMedia: Label[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    spanGaps: true,
    elements: {
      line: {
        tension: 0.2
      }
    }
  };
  public lineChartColorsFollower: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public lineChartColorsFollowing: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(128,255,66,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [];

  constructor(
    private instaAccountService: InstaAccountsService,
  ) {
  }

  static addDays(addToDate: Date, days) {
    const date = new Date(addToDate);
    date.setDate(date.getDate() + days);
    return date;
  }

  static getDates(startDate, stopDate) {
    const dateArray = [];
    let currentDate = startDate;
    while (currentDate <= this.addDays(stopDate, 1)) {
      dateArray.push(new Date(currentDate));
      currentDate = MyLineChartComponent.addDays(currentDate, 1);
    }
    return dateArray;
  }

  static parseData(data) {
    const createdDate = new Date(data[0].created);
    const days = MyLineChartComponent.getDates(createdDate, new Date());

    const chartLabels = [];
    const chartData = {
      media: [],
      follower: [],
      following: [],
      usertags: [],
      geoMedia: []
    };


    let i = 0;
    let countRound = 0;
    for (const day of days) {

      if (data.length > i && day.toLocaleDateString() === new Date(data[i].created).toLocaleDateString()) {
        chartData.media.push(data[i].media_count);
        chartData.follower.push(data[i].follower_count);
        chartData.following.push(data[i].following_count);
        chartData.usertags.push(data[i].usertags_count);
        chartData.geoMedia.push(data[i].geo_media_count);
        i = i + 1;
      } else {
        chartData.media.push(null);
        chartData.follower.push(null);
        chartData.following.push(null);
        chartData.usertags.push(null);
        chartData.geoMedia.push(null);
      }

      let displayDate = day.toLocaleDateString();
      if (countRound !== 0 && data.length !== i) {
        displayDate = `${day.getDate()}.${day.getMonth() + 1}.`;
      }
      chartLabels.push(displayDate);
      countRound = countRound + 1;
    }

    return {
      chartData,
      chartLabels
    };
  }

  ngOnInit() {
    this.loadChart();
  }

  loadChart() {
    this.instaAccountService.getAccountStats(this.username).subscribe(data => {
      const chartDataset = MyLineChartComponent.parseData(data);

      this.lineChartDataFollower = [{
        data: chartDataset.chartData.follower, label: 'Follower'
      }];
      this.lineChartDataFollowing = [{
        data: chartDataset.chartData.following, label: 'Following'
      }];
      this.lineChartDataMedia = [{
        data: chartDataset.chartData.media, label: 'Media'
      }];
      this.lineChartDataUsertags = [{
        data: chartDataset.chartData.usertags, label: 'User Tags'
      }];
      this.lineChartDataGeoMedia = [{
        data: chartDataset.chartData.geoMedia, label: 'Geo Media'
      }];

      this.lineChartLabelsFollower = chartDataset.chartLabels;
      this.lineChartLabelsFollowing = chartDataset.chartLabels;
      this.lineChartLabelsMedia = chartDataset.chartLabels;
      this.lineChartLabelsGeoMedia = chartDataset.chartLabels;
      this.lineChartLabelsUsertags = chartDataset.chartLabels;
    });
  }
}
