import {Component, OnInit} from '@angular/core';
import {PostService} from '../../../core/services/post.service';
import {StatsService} from '../../../core/services/stats.service';

@Component({
  selector: 'app-top-posts',
  templateUrl: './top-posts.component.html',
  styleUrls: ['./top-posts.component.sass']
})
export class TopPostsComponent implements OnInit {

  posts = [];

  constructor(
    private postService: PostService,
    private statsService: StatsService) {
  }

  ngOnInit() {
    this.getPostByLikes();
  }

  getPostByLikes() {
    this.posts = [];
    this.statsService.getTopPostsByLikes().subscribe(data => {
      for (const obj of data) {
        this.posts.push({
          stats: obj,
          post: null
        });
      }
      this.getPostDetails();
    });
  }

  getPostByComments() {
    this.posts = [];
    this.statsService.getTopPostsByComments().subscribe(data => {
      for (const obj of data) {
        this.posts.push({
          stats: obj,
          post: null
        });
      }
      this.getPostDetails();
    });
  }

  getPostsByViews() {
    this.posts = [];
    this.statsService.getTopPostsByViews().subscribe(data => {
      for (const obj of data) {
        this.posts.push({
          stats: obj,
          post: null
        });
      }
      this.getPostDetails();
    });
  }

  getPostDetails() {
    for (const obj of this.posts) {
      const pos = this.posts.indexOf(obj);
      this.postService.getPost(obj.stats.post_id).subscribe(data => {
        this.posts[pos] = {
          stats: obj.stats,
          post: data
        };
      });
    }
  }

}
