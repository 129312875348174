import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-post-stats',
  templateUrl: './post-stats.component.html',
  styleUrls: ['./post-stats.component.sass']
})
export class PostStatsComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
