import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserService} from './user.service';
import {take} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  isAuth: boolean;
  isAuthObs: Observable<boolean>;

  constructor(
    private router: Router,
    private userService: UserService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.isAuthObs = this.userService.isAuthenticated.pipe(take(1));

    // this.isAuthObs.subscribe(data => {
    //   this.isAuth = data;
    // });
    // if (!this.isAuth) {
    //   this.router.navigateByUrl('/user/login');
    //   return this.isAuthObs;
    // }
    // return this.isAuthObs;
  }
}
