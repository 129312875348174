import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HomeRoutingModule} from './home-routing.module';
import {HomeComponent} from './home.component';
import {MatProgressSpinnerModule} from '@angular/material';
import {StatsModule} from '../stats/stats.module';

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    MatProgressSpinnerModule,
    StatsModule,
  ],
  providers: [],
  exports: []
})
export class HomeModule {
}
