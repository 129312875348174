import {Component, OnInit} from '@angular/core';
import * as Chart from 'chart.js';
import {StatsService} from '../../../core/services/stats.service';

@Component({
  selector: 'app-stats-accounts-grouped',
  templateUrl: './grouped.component.html',
  styleUrls: ['./grouped.component.sass']
})
export class GroupedComponent implements OnInit {
  accGroupedFollower;
  barchartGrouped;

  constructor(
    private statsService: StatsService
  ) {
  }

  ngOnInit() {
    this.statsService.getAccountsGroupedByFollower().subscribe(data => {
      this.accGroupedFollower = data;
      this.getAccGroupedBarChart(data);
    });
  }

  getAccGroupedBarChart(rData) {
    const data = {
      labels: [],
      datasets: [
        {
          label: 'Accounts Grouped By Follower',
          backgroundColor: ['#3e95cd', '#8e5ea2', '#3cba9f', '#e8c3b9', '#c45850', '#d8cf50', '#9e3c8c'],
          data: []
        }
      ]
    };


    for (const ele of rData) {
      data.datasets[0].data.push(ele.count);
      data.labels.push(ele.range.start + '-' + ele.range.end);
    }

    this.barchartGrouped = new Chart('barAccountsGrouped', {
      data,
      type: 'bar',
    });

    this.barchartGrouped = new Chart('pieAccountsGrouped', {
      data,
      type: 'pie',
    });
  }

}
