import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import UIkit from 'uikit';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
  ) {
  }

  public static getOffsetParameter(page: number, limit = 100) {
    const offset = (page - 1) * limit;
    return `?limit=${limit}&offset=${offset}`;
  }

  public static getTotalPages(count: number, limit = 100) {
    return Math.ceil(count / limit);
  }

  private static formatErrors(error: any) {
    UIkit.notification(error.error.detail, {status: 'danger'});
    return throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams(), fullPath = false, page = null): Observable<any> {
    let parameter = '';
    if (page) {
      parameter = ApiService.getOffsetParameter(page);
    }

    let url = path + parameter;
    if (!fullPath) {
      url = `${environment.api_url}${path}${parameter}`;
    }
    return this.http.get(url, {params})
      .pipe(catchError(ApiService.formatErrors));
  }

  put(path: string, body: {}): Observable<any> {
    return this.http.put(
      `${environment.api_url}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(ApiService.formatErrors));
  }

  post(path: string, body: {}): Observable<any> {
    return this.http.post(
      `${environment.api_url}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(ApiService.formatErrors));
  }

  delete(path): Observable<any> {
    return this.http.delete(
      `${environment.api_url}${path}`
    ).pipe(catchError(ApiService.formatErrors));
  }
}
