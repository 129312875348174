import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../../core/services/user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  @Input() currentUser;

  constructor(
    private userService: UserService,
    private router: Router,
  ) {
  }

  ngOnInit() {
  }

  logout() {
    this.userService.purgeAuth();
    this.router.navigateByUrl('/');
  }
}
