import {Injectable} from '@angular/core';
import {User} from '../models/user.model';


@Injectable()
export class JwtService {

  static getToken(): string {
    return localStorage.getItem('token');
  }

  static saveToken(token: string) {
    localStorage.setItem('token', token);
  }

  static destroyToken() {
    localStorage.removeItem('token');
  }

  static getUser() {
    return localStorage.getItem('userId');
  }

  static saveUser(user: User) {
    if (user.userId) {
      localStorage.setItem('userId', (user.userId).toString());
    }
  }

  static destroyUser() {
    localStorage.removeItem('userId');
  }

}
