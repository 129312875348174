import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(private apiService: ApiService) {
  }

  getPost(postId) {
    return this.apiService.get(`posts/${postId}/`);
  }

}
