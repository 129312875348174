import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {ListErrorsComponent} from './list-errors/list-errors.component';
import {LoginComponent} from '../profile/login/login.component';
import {RouterModule} from '@angular/router';
import {ChartsModule} from 'ng2-charts';
import {MyLineChartComponent} from './charts/my-line-chart/my-line-chart.component';

@NgModule({
  declarations: [
    ListErrorsComponent,
    LoginComponent,
    MyLineChartComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    ChartsModule
  ],
  exports: [
    ListErrorsComponent,
    ReactiveFormsModule,
    MyLineChartComponent,
  ]
})
export class SharedModule {
}
