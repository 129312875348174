import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class InstaAccountsService {

  constructor(
    private apiService: ApiService
  ) {
  }

  getAccounts(page = null): Observable<any> {
    return this.apiService.get('accounts/', undefined, undefined, page);
    // .pipe(map((data) => {
    // console.log(data);
    // return data.results;
    // }));
  }

  getAccountDetail(username): Observable<any> {
    return this.apiService.get(`accounts/${username}/`);
  }

  scrapeAccount(username): Observable<any> {
    return this.apiService.get(`accounts/${username}/scrape`);
  }

  updateAccount(username): Observable<any> {
    return this.apiService.get(`accounts/${username}/update`);
  }

  searchAccount(value): Observable<any> {
    return this.apiService.get(`accounts/?search=${value}`);
  }

  getAccountStats(username): Observable<any> {
    return this.apiService.get(`accounts/${username}/stats/`);
  }

  getAccountFollowerInterests(username): Observable<any> {
    return this.apiService.get(`accounts/${username}/follower_interests/`);
  }

  getAccountPosts(username): Observable<any> {
    return this.apiService.get(`accounts/${username}/posts/?limit=30`);
  }

  getHashtagsGrouped(username): Observable<any> {
    return this.apiService.get(`accounts/${username}/hashtags/grouped/`);
  }

  getMentionsGrouped(username): Observable<any> {
    return this.apiService.get(`accounts/${username}/mentions/grouped/`);
  }

  getAccountMetaData(username): Observable<any> {
    return this.apiService.get(`accounts/${username}/meta/`);
  }

}
